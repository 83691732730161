import React, {useState} from "react";
import styles from './Tooltip.module.scss';
import addClassNames from "../classNameUtils";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  text: string | JSX.Element
  placement?: "top" | "bottom" | "left" | "right"
  arrow?: boolean
  delay?: number
  isDisplayed?: boolean,
  tipStyle?: React.CSSProperties,
  wrapperClassName?: string,
  wrapperStyle?: React.CSSProperties,
  mode?: 'light' | 'dark'
}

export default function Tooltip(props: Props): JSX.Element {
  const {
    children,
    className,
    text,
    placement = 'bottom',
    arrow = true,
    delay = 400,
    isDisplayed = true,
    tipStyle,
    wrapperClassName,
    mode = 'dark',
    ...rest
  } = props;
  const [active, setActive] = useState(false);
  let timeout: NodeJS.Timeout;

  function showTooltip() {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay)
  }

  function hideTooltip() {
    clearInterval(timeout);
    setActive(false)
  }

  const classNames = [
    {className: styles.tooltip, condition: true},
    {className: styles[placement], condition: true},
    {className: styles.noArrow, condition: !arrow},
    {className: styles.lightMode, condition: mode === 'light'},
    {className: className, condition: !!className}
  ]

  return <div style={props.wrapperStyle}
              className={addClassNames([
                {className: styles.wrapper, condition: true},
                {className: wrapperClassName, condition: !!wrapperClassName},
              ])}
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
              {...rest}>
    {children}
    {active && isDisplayed && (
      <div className={addClassNames(classNames)} style={tipStyle}>
        {text}
      </div>
    )}
  </div>
}
